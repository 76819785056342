
// NOTE: we wrapped the worker in this if statement to avoid the uncaught ReferenceError: importScripts is not defined error.
if (typeof window === 'undefined') {
  importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-app-compat.js');
  importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging-compat.js');

  firebase.initializeApp({
    apiKey: 'AIzaSyCNQDlnCwHSI949KczxqxZN5vDnTX9-uxw',
    authDomain: 'number-hive.firebaseapp.com',
    projectId: 'number-hive',
    storageBucket: 'number-hive.appspot.com',
    messagingSenderId: '285194478935',
    appId: '1:285194478935:web:b0866c3747d2c0013bca12',
    measurementId: 'G-B4T8JQR8SF',
  });

  const messaging = firebase.messaging();

  messaging.onBackgroundMessage(function (payload) {
    clients.matchAll({ type: 'window', includeUncontrolled: true }).then(function (clientList) {
      clientList.forEach(function (client) {
        client.postMessage({
          type: 'NOTIFICATION_RECEIVED',
          payload: payload,
        });
      });
    });
  });
}

